<template>
  <div class="fill-height">
    <v-container fluid class="container-main">
      <v-row>
        <v-col cols="12" md="8" xl="7">
          <h2>{{ name }}</h2>
          <p class="text-body-1 mb-1">
            {{ status }}
          </p>
          <p class="text-body-1 font-italic text--secondary mb-1">
            Submitted On {{ dateSubmittedFormatted }}
          </p>
          <p class="text-body-1 font-italic text--secondary mb-1">
            Last Updated {{ dateUpdatedFormatted }}
          </p>
          <div class="mb-2 scroll-multiline">
            <p
              class="text-body-1 font-italic text--secondary mb-0"
              v-for="(note, i) in noteArr"
              :key="note + i"
            >
              {{ note }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="4" xl="5">
          <h3>Review Comments</h3>
          <p
            v-if="isReviewed"
            class="text-body-1 font-italic text--secondary mb-2"
          >
            Reviewed by {{ reviewerDisplay }} on {{ dateReviewedFormatted }}
          </p>
        </v-col>
      </v-row>
      <v-system-bar color="accent" height="5" class="mb-4"></v-system-bar>
      <review-comment
        :currentHref="`/ResumeReview/${id}`"
        :imageRef="imageRef"
        v-model="comments"
        :errorOnLoad="errorOnLoad"
        :userId="userId"
        :reviewId="id"
      ></review-comment>
    </v-container>
  </div>
</template>

<script>
import * as disp from "../../utility/display.js";
import ReviewAndComment from "../../components/reviewer/ReviewAndComment.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as dUtil from "../../utility/dateutil";

// @ is an alias to /src
export default {
  name: "ResumeReview",
  data: () => ({
    errorOnLoad: false,
  }),
  components: {
    "review-comment": ReviewAndComment,
  },
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.id != pageKey) {
      this.id = pageKey;
    }

    this.setErrorOnLoad();

    this.loadReviewUser({ reviewId: this.id }).then(this.setErrorOnLoad);

    // setup
    this.comments.forEach((c) => {
      c.config.fill = this.unselectedFill;
      c.config.id = c.id;
    });
  },
  methods: {
    ...mapActions("currentReview", ["loadReviewUser"]),
    setErrorOnLoad() {
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.errorOnLoad = true;
      } else {
        this.errorOnLoad = false;
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
  computed: {
    ...mapFields("currentReview", [
      "loadResumeError",
      "comments",
      "imageRef",
      "name",
      "note",
      "dateSubmitted",
      "userDisplay",
      "userId",
      "id",
      "reviewerDisplay",
      "dateReviewed",
      "dateUpdated",
      "status",
    ]),
    dateSubmittedFormatted() {
      return dUtil.dateStringToFormat(this.dateSubmitted);
    },
    dateUpdatedFormatted() {
      return dUtil.dateStringToFormat(this.dateUpdated);
    },
    dateReviewedFormatted() {
      return dUtil.dateStringToFormat(this.dateReviewed);
    },
    isReviewed() {
      return this.status === "Reviewed";
    },
    noteArr() {
      if (this.note == null || this.note == "") {
        return [];
      }

      let noteSplit = this.note.split("\n");
      if (noteSplit.length == 1) {
        noteSplit[0] = "Submission Notes: " + noteSplit[0];
      } else {
        noteSplit.unshift("Submission Notes: ");
      }

      return noteSplit;
    },
  },
};
</script>


<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  max-height: 816px;
  max-width: 636px;
}

.resume-img {
  max-width: 100%;
  height: auto;
}

.comment-highlight {
  background-color: #df202550;
}
</style>